import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BaseDialogContentComponent } from '../../dialog/base-dialog-content.component';

interface AddParticipantResult {
    participantURI: string;
    isHost: boolean;
}

interface AddParticipantConfig {
    previousURI?: string;
    isHost?: boolean;
}

@Component({
    templateUrl: './add-participant-dialog.component.html',
    styleUrls: ['./add-participant-dialog.component.scss']
})
export class AddParticipantDialogComponent extends BaseDialogContentComponent<
    AddParticipantResult,
    AddParticipantConfig
> {
    isHost = false;
    // private searchDebounceTime = 300;
    participantFormControl = new UntypedFormControl();
    directoryResults: string[] = [];

    @ViewChild('participantAutocomplete', { read: ElementRef, static: false })
    participantAutocomplete: ElementRef;
    @ViewChild('participantURI', { read: ElementRef, static: false })
    participantURI: ElementRef;

    afterInit() {
        if (this.config) {
            this.participantFormControl.setValue(this.config.previousURI);
            this.isHost = this.config.isHost;
            setTimeout(() => {
                this.participantURI.nativeElement.focus();
            }, 0);
        }

        // const searchDebounce = this.participantFormControl.valueChanges
        //     .debounceTime(this.searchDebounceTime)
        //     .share();

        // searchDebounce.switchMap(searchTerm => this.registrationService.directoryQuery(searchTerm))
        //     .filter((response: any) => response.status === 'success')
        //     .subscribe((response: any) => {
        //         this.directoryResults = response.result;
        //     });
    }

    addParticipant() {
        this.close({
            participantURI: this.participantFormControl.value,
            isHost: this.isHost
        });
    }

    @HostListener('click', ['$event'])
    onOutsideClick(event: MouseEvent): void {
        if (!this.participantAutocomplete) {
            return;
        }

        const clickedInside = this.participantAutocomplete.nativeElement.contains(
            event.target
        );
        if (!clickedInside) {
            this.directoryResults = [];
        }
    }
}
