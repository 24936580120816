import { Injectable, NgModule } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Params,
    PreloadAllModules,
    Router,
    RouterModule,
    Routes
} from '@angular/router';
import { HomeResolver } from 'src/core/home/home.resolver';

import { BaseComponent } from '../core/base/base.component';

@Injectable()
export class LegacyRedirectService {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let fragmentParams = new URLSearchParams();

        if (route.fragment) {
            const queryIndex = route.fragment.indexOf('?');
            if (queryIndex > -1) {
                const fragmentQuery = route.fragment.slice(queryIndex);
                fragmentParams = new URLSearchParams(fragmentQuery);
            }
        }

        if (route.queryParams && route.queryParams['conference']) {
            if (route.queryParams && +route.queryParams.join) {
                this.router.navigate(['/conference'], {
                    queryParams: route.queryParams,
                    queryParamsHandling: 'merge'
                });
            } else {
                this.router.navigate(['/home'], {
                    queryParams: route.queryParams,
                    queryParamsHandling: 'merge'
                });
            }
        } else if (fragmentParams.has('conference')) {
            const queryParams: Params = {};

            fragmentParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            this.router.navigate(['/conference'], {
                queryParams,
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigateByUrl('/home');
        }

        return false;
    }
}

const appRoutes: Routes = [
    {
        path: '',
        component: BaseComponent,
        canActivate: [LegacyRedirectService],
        pathMatch: 'full'
    },
    {
        path: 'conference',
        loadChildren: () =>
            import('../core/conference/conference.module').then(
                m => m.ConferenceModule
            )
    },
    {
        path: 'home',
        loadChildren: () =>
            import('../core/home/home.module').then(m => m.HomeModule),
        resolve: {
            homeResolver: HomeResolver
        }
    },
    {
        path: '**',
        component: BaseComponent,
        canActivate: [LegacyRedirectService],
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules
        })
    ],
    declarations: [BaseComponent],
    exports: [RouterModule],
    providers: [LegacyRedirectService]
})
export class AppRoutingModule {}
