import { BehaviorSubject } from 'rxjs';

import { Space } from '../my-space.service';
import { PexStorage } from './storage';

export class MySpacesStorage extends PexStorage<MySpaceModel> {
    readonly storageString = 'pexSpaces';
    spaces$: BehaviorSubject<MySpaceModel>;

    constructor(values: MySpaceModel) {
        super(new MySpaceModel(), values);
        this.spaces$ = new BehaviorSubject(this.values);
    }

    reset() {
        super.reset();
        if (localStorage.getItem(this.storageString)) {
            localStorage.removeItem(this.storageString);
        }
        this.spaces$.next({ spaces: [], activeSpace: null });
    }
}

export class MySpaceModel {
    spaces: Space[] = [];
    activeSpace: string | undefined = undefined;
}
