import {
    Component,
    HostBinding,
    Injector,
    NgZone,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Event, NavigationStart, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { BrandingService, StorageService } from './services';

import { MediaDeviceService } from './media-device';

import { InitService } from './init.service';
import { LoggingService } from './logging.service';
import { MediaDeviceFacade } from './media-device/media-device.facade';
import { PlatformService } from './platform.service';
import { GeneralFacade } from './shared/general/general.facade';

@Component({
    selector: 'pex-app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    private visibiltyFunction: () => void;
    private pauseFunction: () => void;
    private resumeFunction: () => void;
    @ViewChild('header', { read: ViewContainerRef, static: true })
    viewContainerRef: ViewContainerRef;

    constructor(
        private platformService: PlatformService,
        private titleService: Title,
        private translate: TranslateService,
        private initService: InitService,
        private loggingService: LoggingService,
        public storageService: StorageService,
        private injector: Injector,
        private ngZone: NgZone,
        private brandingService: BrandingService,
        private generalFacade: GeneralFacade,
        private mediaDeviceFacade: MediaDeviceFacade
    ) {}

    @HostBinding('class.high-contrast') highContrast = this.storageService
        .userSettingsProxy.highContrast;

    ngOnInit() {
        this.visibiltyFunction = () =>
            this.ngZone.run(() => this.handleVisibilityChange());
        this.pauseFunction = () => this.ngZone.run(() => this.paused());
        this.resumeFunction = () => this.ngZone.run(() => this.resumed());

        if (
            this.platformService.platform === 'web' ||
            this.platformService.isIOS()
        ) {
            document.addEventListener(
                'visibilitychange',
                this.visibiltyFunction
            );
        }

        if (this.platformService.platform === 'android') {
            document.addEventListener('onstop', this.pauseFunction);
            document.addEventListener('onstart', this.resumeFunction);
        }

        this.injector.get(Router).events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if (event.url.includes('branding=true')) {
                    window.pexBranding = true;
                }

                if (event.url === '/' || event.url.startsWith('/home')) {
                    this.injector
                        .get(MediaDeviceService)
                        .enumerateMediaDevices();

                    if (
                        ['ios', 'android'].indexOf(
                            this.platformService.platform
                        ) > -1
                    ) {
                        if (this.needsOrientation())
                            (window.screen.orientation as any)
                                .lock('portrait')
                                .catch((err: string) => {
                                    this.loggingService.warn(
                                        'Orientation lock error',
                                        err
                                    );
                                });
                    }
                } else {
                    const queryIndex = event.url.indexOf('?');
                    if (
                        event.url.startsWith('/conference') &&
                        queryIndex > -1
                    ) {
                        const params = new URLSearchParams(
                            event.url.substr(queryIndex)
                        );
                        if (params.has('callType')) {
                            const callType = params.get('callType');
                            if (
                                ['video', 'audioonly', 'none'].includes(
                                    callType
                                )
                            ) {
                                this.mediaDeviceFacade.setMediaTypeRequest(
                                    callType as CallType
                                );
                            }
                        }
                    }

                    // need to be after the above mediaTypeRequest$ set
                    this.injector
                        .get(MediaDeviceService)
                        .enumerateMediaDevices();

                    if (
                        ['ios', 'android'].indexOf(
                            this.platformService.platform
                        ) > -1
                    ) {
                        if (this.needsOrientation())
                            window.screen.orientation.unlock();
                    }
                }
            }
        });

        this.initService.init();

        this.storageService.userSettingsStorage.listeners.highContrast$.subscribe(
            highContrast => {
                this.highContrast = highContrast;
            }
        );

        this.translate
            .get('APPLICATION.TITLE')
            .subscribe(title => this.titleService.setTitle(title));

        if (['web', 'electron'].indexOf(this.platformService.platform) > -1) {
            window.addEventListener(
                'dragover',
                (e: DragEvent) => {
                    e = e || (event as DragEvent);
                    e.preventDefault();
                },
                false
            );
            window.addEventListener(
                'drop',
                (e: DragEvent) => {
                    e = e || (event as DragEvent);
                    e.preventDefault();
                },
                false
            );
        }

        this.storageService.loaded$.subscribe({
            complete: () => {
                if (this.brandingService.isCustomHeaderAdded) {
                    const injector = Injector.create({
                        providers: [],
                        parent: this.injector
                    });
                    this.viewContainerRef.createComponent(
                        this.brandingService.customHeaderComponent,
                        null,
                        injector
                    );
                }
            }
        });
    }

    private handleVisibilityChange() {
        if (this.generalFacade) {
            this.generalFacade.setWindowActive(!document.hidden);
        }
    }

    private needsOrientation() {
        return (
            window.screen &&
            window.screen.orientation &&
            (window.screen.orientation as any).lock &&
            window.screen.availHeight < __CONSTANTS__.WIDTH_BREAKPOINT
        );
    }

    private paused() {
        if (this.generalFacade) {
            this.generalFacade.setWindowActive(false);
        }
    }

    private resumed() {
        if (this.generalFacade) {
            this.generalFacade.setWindowActive(true);
        }
    }
}
