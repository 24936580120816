import { BehaviorSubject, Observable } from 'rxjs';

interface LoadPluginFunction {
    (...args: object[]): void;
}

interface UnloadPluginFunction {
    (): void;
}

export interface Plugin {
    id: string;

    load: LoadPluginFunction;
    unload: UnloadPluginFunction;
    clickAction?: Function;
    state$?: BehaviorSubject<{ icon?: string; label?: string }>;
}

export interface PluginMetaData {
    id: string;
    name: string;
    description: string;
    version: string;
    srcURL: string;
    allowUnload: boolean;
    platforms: string[];
    participantRoles: string[];
    pluginBaseURL?: string;

    scriptElement: HTMLScriptElement;
    plugin: Plugin;
    failedToLoad?: boolean;

    menuItems?: {
        participants?: MenuItemJSON[];
        conference?: MenuItemJSON[];
        toolbar?: MenuItemJSON[];
        cmdLine?: MenuItemJSON[];
    };
}

export type MenuItemJSON = {
    label?: string;
    action?: string;
    icon?: string;
    id?: string;
};

export class MenuItem {
    public visible$ = new BehaviorSubject<boolean>(true);
    private _visible = true;
    get visible() {
        return this._visible;
    }
    public id = Math.random().toString(36).substring(2);

    constructor(
        public pluginMetaData: PluginMetaData,
        public label: string,
        public action: string,
        public icon?: Observable<string>,
        id?: string
    ) {
        if (id) {
            this.id = id;
        }
    }

    get pluginId() {
        return this.pluginMetaData.id;
    }

    show() {
        this._visible = true;
        this.visible$.next(true);
    }
    hide() {
        this._visible = false;
        this.visible$.next(false);
    }

    // tslint:disable-next-line:no-any
    click(...args: any[]) {
        // TODO: should probably be wrapped in ngZone.runOutsideAngular
        // to protect app against setTimeout etc. initiated by plugin
        this.pluginMetaData.plugin[this.action](...args);
    }
}
