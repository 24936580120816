import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services';

@Injectable({ providedIn: 'root' })
export class HomeResolver {
    constructor(private storageService: StorageService) {}

    resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Promise<void> {
        return new Promise<void>(resolve => {
            this.storageService.loaded$.subscribe({
                complete: () => resolve()
            });
        });
    }
}
