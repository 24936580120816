import {
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    NgZone,
    Type
} from '@angular/core';
import { take } from 'rxjs/operators';

import {
    ActionsDialogComponent,
    ActionsDialogConfig
} from './actions-dialog.component';
import { BaseDialogContainerComponent } from './base-dialog-container.component';
import {
    BaseDialogContentComponent,
    DialogConfigType,
    DialogReturnType
} from './base-dialog-content.component';
import { CardDialogContainerComponent } from './card-dialog/card-dialog-container.component';
import {
    CardDialogComponent,
    CardDialogConfig
} from './card-dialog/card-dialog.component';
import { DialogContainerComponent } from './dialog-container.component';
import {
    FlexContainerConfig,
    FlexDialogContainerComponent
} from './flex-dialog/flex-dialog-container.component';
import { FloatingDialogContainerComponent } from './floating-dialog-container.component';
import { FullscreenDialogContainerComponent } from './fullscreen-dialog/fullscreen-dialog-container.component';
import {
    TemplateDialogComponent,
    TemplateDialogConfig
} from './template-dialog.component';

export type DialogUnion<
    T extends BaseDialogContentComponent = BaseDialogContentComponent
> =
    | DialogContainerComponent<T>
    | FloatingDialogContainerComponent<T>
    | FullscreenDialogContainerComponent<T>
    | CardDialogContainerComponent<T>
    | FlexDialogContainerComponent<T>;
export type DialogType = DialogUnion['type'];
export type ExtractDialog<
    T,
    U extends BaseDialogContentComponent = BaseDialogContentComponent
> = T extends DialogType
    ? Extract<DialogUnion<U>, { type: T }>
    : DialogContainerComponent<U>;

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private openDialogs: {
        [id: string]: ComponentRef<
            BaseDialogContainerComponent<BaseDialogContentComponent>
        >;
    } = {};

    constructor(
        private injector: Injector,
        private cfr: ComponentFactoryResolver,
        private ngZone: NgZone
    ) {}

    openCardDialog(config: CardDialogConfig, priority?: number) {
        return this.open(
            CardDialogComponent,
            config,
            'card',
            undefined,
            priority
        );
    }

    openTemplateDialog<T extends DialogType = 'static'>(
        contentConfig: TemplateDialogConfig,
        type?: T,
        priority?: number,
        containerConfig?: unknown
    ): Promise<ExtractDialog<T, TemplateDialogComponent>>;
    openTemplateDialog(
        contentConfig: TemplateDialogConfig,
        type: DialogType = 'static',
        priority?: number,
        containerConfig?: unknown
    ) {
        return this.open(
            TemplateDialogComponent,
            contentConfig,
            type,
            containerConfig,
            priority
        );
    }

    openActionDialog<T extends DialogType = 'static'>(
        contentConfig: ActionsDialogConfig,
        type?: T,
        priority?: number
    ): Promise<ExtractDialog<T, ActionsDialogComponent>>;
    openActionDialog(
        contentConfig: ActionsDialogConfig,
        type: DialogType = 'static',
        priority?: number
    ) {
        return this.open(
            ActionsDialogComponent,
            contentConfig,
            type,
            undefined,
            priority
        );
    }

    open<
        S extends BaseDialogContentComponent<U, V>,
        T extends DialogType = 'static',
        U = DialogReturnType<S>,
        V = DialogConfigType<S>
    >(
        componentType: Type<S>,
        contentConfig?: S['config'],
        type?: T,
        containerConfig?: unknown,
        priority?: number,
        id?: string
    ): Promise<Extract<DialogUnion<S>, { type: T }>>;
    open(
        componentType: Type<BaseDialogContentComponent>,
        contentConfig?: unknown,
        type: DialogType = 'static',
        containerConfig?: unknown,
        priority?: number,
        id?: string
    ) {
        if (id) {
            if (this.openDialogs[id]) {
                return Promise.resolve(this.openDialogs[id]);
            }
        } else {
            id = new Date().valueOf() + Math.random().toFixed(16).substring(2);
        }
        const activeElement = document.activeElement as HTMLElement;

        return new Promise(resolve => {
            setTimeout(() => {
                this.ngZone.run(() => {
                    //Factory for container
                    const containerFactory = this.getDialogContainerFactory(
                        type
                    );

                    const destroyFunction = () => {
                        this.destroyDialog(id);
                    };

                    let injector = this.injector;

                    if (type === 'flex') {
                        if (!containerConfig) {
                            containerConfig = new FlexContainerConfig();
                        }
                        injector = Injector.create({
                            providers: [
                                {
                                    provide: 'CONFIG',
                                    useValue: containerConfig
                                }
                            ],
                            parent: this.injector
                        });
                    }

                    //Create container
                    const containerComponentRef = containerFactory.create(
                        injector
                    );
                    const containerDomElem = (containerComponentRef.hostView as EmbeddedViewRef<unknown>)
                        .rootNodes[0] as HTMLElement;

                    containerComponentRef.instance.init(
                        id,
                        destroyFunction,
                        containerDomElem,
                        priority
                    );
                    //Attach container to app
                    this.injector
                        .get(ApplicationRef)
                        .attachView(containerComponentRef.hostView);

                    const homeSlot = document.getElementById(
                        'home-dialog-slot'
                    );

                    //Add container to DOM
                    if (type === 'card' && homeSlot) {
                        homeSlot.appendChild(containerDomElem);
                    } else {
                        document.body.appendChild(containerDomElem);
                    }

                    //Factory for content
                    const contentFactory = this.cfr.resolveComponentFactory(
                        componentType
                    );
                    //Pass factory to container so it can inject content
                    containerComponentRef.instance.addContent(
                        contentFactory,
                        contentConfig
                    );

                    this.openDialogs[
                        containerComponentRef.instance.id
                    ] = containerComponentRef;

                    containerComponentRef.instance.close$
                        .pipe(take(1))
                        .subscribe(_ => {
                            activeElement?.focus?.();
                        });
                    containerComponentRef.instance.viewInit
                        .pipe(take(1))
                        .subscribe(() => {
                            resolve(containerComponentRef.instance);
                        });
                });
            }, 0);
        });
    }

    private destroyDialog(id: string) {
        const dialog = this.openDialogs[id];
        if (dialog) {
            this.injector.get(ApplicationRef).detachView(dialog.hostView);
            dialog.destroy();
        }
        delete this.openDialogs[id];
    }

    close(id: string) {
        const dialog = this.openDialogs[id];
        if (dialog) {
            dialog.instance.close();
        }
    }

    closeAllDialogs() {
        for (const id of Object.keys(this.openDialogs)) {
            this.close(id);
        }
    }

    closeByType(type: Type<BaseDialogContentComponent>) {
        this.getDialogRefs(type).forEach(dialogRef => {
            dialogRef.instance.close();
        });
    }

    getDialogRef(id: string) {
        return this.openDialogs[id];
    }

    getDialogRefs<T extends BaseDialogContentComponent>(
        type: Type<T>
    ): ComponentRef<BaseDialogContainerComponent<T>>[];
    getDialogRefs(type: Type<BaseDialogContentComponent>) {
        return Object.values(this.openDialogs).filter(
            dialogRef => dialogRef.instance.contentComponent instanceof type
        );
    }

    getDialogComponent(id: string) {
        const dialogRef = this.getDialogRef(id);

        if (dialogRef) {
            return dialogRef.instance;
        }
    }

    getDialogComponents<T extends BaseDialogContentComponent>(
        type: Type<T>
    ): BaseDialogContainerComponent<T>[];
    getDialogComponents(type: Type<BaseDialogContentComponent>) {
        return this.getDialogRefs(type).map(dialogRef => dialogRef.instance);
    }

    getAllDialogComponents() {
        return Object.values(this.openDialogs).map(
            dialogRef => dialogRef.instance
        );
    }

    getAllDialogContents() {
        return Object.values(this.openDialogs).map(
            dialogRef =>
                dialogRef.instance && dialogRef.instance.contentComponent
        );
    }

    isDialogContentOpen(type: Type<BaseDialogContentComponent>) {
        return Object.values(this.openDialogs).some(
            dialogRef => dialogRef.instance.contentComponent instanceof type
        );
    }

    private getDialogContainerFactory<T extends DialogType>(
        type: T
    ): ComponentFactory<Extract<DialogUnion, { type: T }>>;
    private getDialogContainerFactory(type: DialogType) {
        let component: Type<DialogUnion>;
        switch (type) {
            case 'static':
                component = DialogContainerComponent;
                break;

            case 'floating':
                component = FloatingDialogContainerComponent;
                break;

            case 'fullscreen':
                component = FullscreenDialogContainerComponent;
                break;

            case 'card':
                component = CardDialogContainerComponent;
                break;

            case 'flex':
                component = FlexDialogContainerComponent;
                break;

            default:
                component = DialogContainerComponent;
        }

        return this.cfr.resolveComponentFactory(component);
    }
}
