import { createReducer, on } from '@ngrx/store';
import { DevicesSetupState } from './../media-device/media-devices/devices-setup-state.type';
import { ConferenceActions, CONFERENCE_ACTIONS } from './conference.actions';
import { SidebarState, SplashScreen } from './conference.model';

interface ConferenceState {
    locked: boolean;
    guestsMuted: boolean;
    chatEnabled: boolean; // introduced in V17
    guestsCanPresent: boolean;
    feccEnabled: boolean;
    liveCaptionsAvailable: boolean;
    isDirectMedia: boolean;
    isBreakoutRoomsEnabled: boolean;

    selfUUID: string;
    remoteMediaStream: MediaStream;
    remoteCallType: 'audio' | 'video-only' | 'video';

    incomingPresentation: boolean;
    presentationImgSrc: string;
    presentationVideo: MediaStream;

    outgoingPresentation: boolean;
    presentingMode: PresentingMode;
    slides: string[];
    currentSlide: number;
    presentingSlides: number[];
    currentPresenterUuid: string;

    connected: boolean;
    mediaType: CallType;
    started: boolean;
    videoLoaded: boolean;

    splashScreen: SplashScreen;
}

interface UIState {
    cameraMuted: boolean;
    microphoneMuted: boolean;
    volumeMuted: boolean;
    sidebar: SidebarState;
    devicesSetup: DevicesSetupState;
    pinOnTop: boolean;
    idle: boolean;
    presentationMaximized: boolean;
    presentationPoppedOut: boolean;
    presentationInMix: boolean;
    openDtmfDialogs: string[];
    openFeccDialogs: string[];
    openInfoDialogs: string[];
    showConferenceSidebar: boolean;
    sidebarExpanded: boolean;
    liveCaptionsEnabled: boolean;
}

export interface State extends ConferenceState, UIState {}

const initialConferenceState: ConferenceState = {
    locked: false,
    guestsMuted: false,
    chatEnabled: true, // introduced in V17
    guestsCanPresent: false,
    feccEnabled: false,
    liveCaptionsAvailable: false,
    isDirectMedia: false,
    isBreakoutRoomsEnabled: false,

    selfUUID: '',
    remoteMediaStream: null,
    remoteCallType: null,

    incomingPresentation: false,
    presentationImgSrc: null,
    presentationVideo: null,

    outgoingPresentation: false,
    presentingMode: null,
    slides: [],
    currentSlide: 0,
    presentingSlides: [],
    currentPresenterUuid: null,

    connected: false,
    mediaType: null,
    started: false,
    videoLoaded: false,

    splashScreen: null
};

const initialUIState: UIState = {
    cameraMuted: false,
    microphoneMuted: false,
    volumeMuted: false,
    sidebar: 'ROSTER',
    devicesSetup: null,
    pinOnTop: false,
    idle: true,
    presentationMaximized: true,
    presentationPoppedOut: false,
    presentationInMix: false,
    openDtmfDialogs: [],
    openFeccDialogs: [],
    openInfoDialogs: [],
    showConferenceSidebar:
        (JSON.parse(localStorage.getItem('pexUserSettingsValues')) || {})
            .showConferenceSidebar || false,
    sidebarExpanded: false,
    liveCaptionsEnabled: false
};

export const initialState: State = {
    ...initialUIState,
    ...initialConferenceState
};

export function reducer(state = initialState, action: ConferenceActions) {
    return uiReducer(conferenceReducer(state, action), action);
}

const conferenceReducer = createReducer(
    initialState,
    on(CONFERENCE_ACTIONS.connectSuccess, (state, action) => ({
        ...state,
        connected: true,
        remoteCallType: action.payload.remoteCallType,
        guestsCanPresent: action.payload.guestsCanPresent,
        feccEnabled: action.payload.feccEnabled
    })),
    on(CONFERENCE_ACTIONS.disconnectSuccess, CONFERENCE_ACTIONS.clear, () => ({
        ...initialState,
        connected: false,
        mediaType: null
    })),
    on(CONFERENCE_ACTIONS.transferSuccess, state => ({
        ...state,
        ...initialConferenceState,
        openDtmfDialogs: initialUIState.openDtmfDialogs,
        openFeccDialogs: initialUIState.openFeccDialogs,
        openInfoDialogs: initialUIState.openInfoDialogs,
        presentationPoppedOut: initialUIState.presentationPoppedOut,
        connected: false,
        mediaType: state.mediaType
    })),
    on(CONFERENCE_ACTIONS.setRemoteCallType, (state, action) => ({
        ...state,
        remoteCallType: action.payload
    })),

    on(CONFERENCE_ACTIONS.setFECCEnabled, (state, action) => ({
        ...state,
        feccEnabled: action.payload
    })),

    on(CONFERENCE_ACTIONS.setGuestsCanPresent, (state, action) => ({
        ...state,
        guestsCanPresent: action.payload
    })),

    on(CONFERENCE_ACTIONS.setChatEnabled, (state, action) => ({
        ...state,
        chatEnabled: action.payload
    })),

    on(CONFERENCE_ACTIONS.setLiveCaptionsAvailable, (state, action) => ({
        ...state,
        liveCaptionsAvailable: action.payload
    })),

    on(CONFERENCE_ACTIONS.setIsDirectMedia, (state, action) => ({
        ...state,
        isDirectMedia: action.payload
    })),

    on(CONFERENCE_ACTIONS.setIsBreakoutRoomsEnabled, (state, action) => ({
        ...state,
        isBreakoutRoomsEnabled: action.payload
    })),

    on(CONFERENCE_ACTIONS.updateMediaTypeAction, (state, action) => ({
        ...state,
        mediaType: action.payload
    })),

    on(CONFERENCE_ACTIONS.setSelfUUID, (state, action) => ({
        ...state,
        selfUUID: action.payload
    })),

    on(CONFERENCE_ACTIONS.setRemoteMediaStream, (state, action) => ({
        ...state,
        remoteMediaStream: action.payload,
        connected: true
    })),

    on(CONFERENCE_ACTIONS.mutedChanged, (state, action) => ({
        ...state,
        guestsMuted: action.payload
    })),

    on(CONFERENCE_ACTIONS.lockedChanged, (state, action) => ({
        ...state,
        locked: action.payload
    })),

    on(CONFERENCE_ACTIONS.startedChanged, (state, action) => ({
        ...state,
        started: action.payload
    })),

    on(CONFERENCE_ACTIONS.updatePresentationVideo, (state, action) => ({
        ...state,
        presentationVideo: action.payload
    })),

    on(CONFERENCE_ACTIONS.stopPresentationVideo, state => {
        const newState = {
            ...state,
            presentationImgSrc: null
        };
        if (!newState.outgoingPresentation) {
            newState.presentationVideo = null;
        }
        return newState;
    }),

    on(CONFERENCE_ACTIONS.slideShareStarted, (state, action) => ({
        ...state,
        presentingMode: action.payload.type,
        presentationImgSrc: state.slides[state.presentingSlides[0]],
        presentationVideo: null,
        outgoingPresentation: true,
        incomingPresentation: false
    })),

    on(CONFERENCE_ACTIONS.screenShareStarted, (state, action) => ({
        ...state,
        presentingMode: action.payload.type,
        outgoingPresentation: true,
        incomingPresentation: false
    })),

    on(CONFERENCE_ACTIONS.presentingStopped, state => {
        const presentingStoppedUpdate: Partial<ConferenceState> = {
            presentingMode: null,
            outgoingPresentation: false
        };
        if (!state.incomingPresentation) {
            presentingStoppedUpdate.presentationImgSrc = null;
            presentingStoppedUpdate.presentationVideo = null;
        }
        if (state.presentingMode === 'screen_http') {
            presentingStoppedUpdate.currentSlide = 0;
            presentingStoppedUpdate.presentingSlides = [];
        }
        return { ...state, ...presentingStoppedUpdate };
    }),

    on(CONFERENCE_ACTIONS.addSlide, (state, action) => ({
        ...state,
        slides: [...state.slides, action.payload]
    })),

    on(CONFERENCE_ACTIONS.updatePresentingSlides, (state, action) => ({
        ...state,
        presentingSlides: action.payload
    })),

    on(CONFERENCE_ACTIONS.removeAllSlides, state => ({
        ...state,
        slides: []
    })),

    on(CONFERENCE_ACTIONS.updateCurrentSlide, (state, action) => ({
        ...state,
        currentSlide: action.payload,
        presentationImgSrc: state.slides[state.presentingSlides[action.payload]]
    })),

    on(CONFERENCE_ACTIONS.setVideoLoaded, (state, action) => ({
        ...state,
        videoLoaded: action.payload
    })),

    on(CONFERENCE_ACTIONS.setCurrentPresenter, (state, action) => {
        const newState = {
            ...state,
            currentPresenterUuid: action.uuid,
            incomingPresentation: action.isActive,
            outgoingPresentation:
                !action.isActive && state.outgoingPresentation,
            presentationImgSrc: null
        };

        if (!state.outgoingPresentation) {
            newState.presentationVideo = null;
        }

        return newState;
    }),

    on(CONFERENCE_ACTIONS.newPresentationImgSrc, (state, action) => ({
        ...state,
        presentationImgSrc: action.payload
    })),

    on(CONFERENCE_ACTIONS.setSplashScreen, (state, action) => {
        if ('text' in action) {
            return { ...state, splashScreen: action, videoLoaded: false };
        } else {
            return { ...state, splashScreen: null, videoLoaded: true };
        }
    })
);

const uiReducer = createReducer(
    initialState,
    on(CONFERENCE_ACTIONS.muteCamera, state => ({
        ...state,
        cameraMuted: true
    })),

    on(CONFERENCE_ACTIONS.unmuteCamera, state => ({
        ...state,
        cameraMuted: false
    })),

    on(CONFERENCE_ACTIONS.muteMicrophone, state => ({
        ...state,
        microphoneMuted: true
    })),

    on(CONFERENCE_ACTIONS.unmuteMicrophone, state => ({
        ...state,
        microphoneMuted: false
    })),

    on(CONFERENCE_ACTIONS.muteVolume, state => ({
        ...state,
        volumeMuted: true
    })),

    on(CONFERENCE_ACTIONS.unmuteVolume, state => ({
        ...state,
        volumeMuted: false
    })),

    on(CONFERENCE_ACTIONS.setSidebarAction, (state, action) => ({
        ...state,
        sidebar: action.payload
    })),

    on(CONFERENCE_ACTIONS.devicesSetupNavigateAction, (state, action) => ({
        ...state,
        sidebar: 'DEVICES_SETUP' as const,
        devicesSetup: action.payload
    })),

    on(CONFERENCE_ACTIONS.setPinOnTop, (state, action) => ({
        ...state,
        pinOnTop: action.payload
    })),

    on(CONFERENCE_ACTIONS.setIdle, (state, action) => ({
        ...state,
        idle: action.payload
    })),

    on(CONFERENCE_ACTIONS.setPresentationMaximized, (state, action) => ({
        ...state,
        presentationMaximized: action.payload
    })),

    on(CONFERENCE_ACTIONS.setPresentationInMixSuccess, (state, action) => {
        const newState = { ...state, presentationInMix: action.payload };
        if (action.payload) {
            newState.presentationImgSrc = null;
            newState.presentationVideo = null;
        }
        return newState;
    }),

    on(CONFERENCE_ACTIONS.setPresentationPoppedOut, (state, action) => ({
        ...state,
        presentationPoppedOut: action.payload
    })),

    on(CONFERENCE_ACTIONS.addDTMFDialog, (state, action) => ({
        ...state,
        openDtmfDialogs: [...state.openDtmfDialogs, action.dialogId]
    })),

    on(CONFERENCE_ACTIONS.removeDTMFDialog, (state, action) => ({
        ...state,
        openDtmfDialogs: state.openDtmfDialogs.filter(
            dtmf => dtmf !== action.payload
        )
    })),

    on(CONFERENCE_ACTIONS.addFECCDialog, (state, action) => ({
        ...state,
        openFeccDialogs: [...state.openFeccDialogs, action.dialogId]
    })),

    on(CONFERENCE_ACTIONS.removeFECCDialog, (state, action) => ({
        ...state,
        openFeccDialogs: state.openFeccDialogs.filter(
            fecc => fecc !== action.payload
        )
    })),

    on(CONFERENCE_ACTIONS.addParticipantInfoDialog, (state, action) => ({
        ...state,
        openInfoDialogs: [...state.openInfoDialogs, action.dialogId]
    })),

    on(CONFERENCE_ACTIONS.removeParticipantInfoDialog, (state, action) => ({
        ...state,
        openInfoDialogs: state.openInfoDialogs.filter(
            uuid => uuid !== action.payload
        )
    })),

    on(CONFERENCE_ACTIONS.toggleSidebar, state => ({
        ...state,
        showConferenceSidebar: !state.showConferenceSidebar
    })),

    on(CONFERENCE_ACTIONS.setShowConferenceSidebar, (state, action) => ({
        ...state,
        showConferenceSidebar: action.payload
    })),
    on(CONFERENCE_ACTIONS.setSidebarExpanded, (state, action) => ({
        ...state,
        sidebarExpanded: action.payload
    })),
    on(CONFERENCE_ACTIONS.openLoginUrl, (state, action) => ({
        ...state,
        redirectUrl: action.payload
    })),
    on(CONFERENCE_ACTIONS.submitSsoToken, (state, action) => ({
        ...state,
        ssoToken: action.payload
    })),
    on(CONFERENCE_ACTIONS.samlLoginError, (state, action) => ({
        ...state,
        loginError: action.payload
    })),
    on(CONFERENCE_ACTIONS.setLiveCaptionsEnabled, (state, action) => {
        const newState = { ...state, liveCaptionsEnabled: action.payload };
        if (!action.payload && state.sidebar === 'CAPTION') {
            newState.sidebar = 'ROSTER';
        }
        return newState;
    }),
    on(CONFERENCE_ACTIONS.updateConference, state => ({
        ...state,
        connected: true
    }))
);
